import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_REMEMBER,
  AUTH_URL,
  AUTH_REFRESH
} from "../actions/auth";
import qs from 'qs';
import { USER_REQUEST } from "../actions/user";
import axios from "axios";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  refreshToken: false,
  loginjson: JSON.parse(localStorage.getItem("user-json")) || "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  loginJson: state => state.loginjson,
  isRefresh: state => state.refreshToken
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({url: AUTH_URL+'master/api/v2/nglogin', data: qs.stringify(user), method: 'POST' , 
        headers: {
          'Accept' : '*/*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(resp => {
            const token = resp.data.accessToken
            localStorage.setItem('user-token', token)
            localStorage.getItem("user-token-refresh",resp.data.refreshToken)
            localStorage.setItem('user-json', JSON.stringify(resp.data))
            axios.defaults.headers.common['Authorization'] = token
            commit(AUTH_SUCCESS, resp)
            commit(AUTH_REMEMBER,user.remember)
            dispatch(USER_REQUEST)
            resolve(resp)
        })
        .catch(err => {
            commit(AUTH_ERROR, err)
            localStorage.removeItem('user-token')
            localStorage.removeItem('user-json')
            localStorage.removeItem('user-token-refresh')
            reject(err)
        })
    });
  },
  [AUTH_REFRESH]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({url: AUTH_URL+'master/api/v2/refreshToken', method: 'GET' , 
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem("user-token-refresh"),
          'Accept' : '*/*',
          'Access-Control-Allow-Origin': '*'
        }})
        .then(resp => {
            const token = resp.data.accessToken
            localStorage.setItem('user-token', token)
            localStorage.setItem('user-json', JSON.stringify(resp.data))
            axios.defaults.headers.common['Authorization'] = token
            commit(AUTH_SUCCESS, resp)
            commit(AUTH_REMEMBER,user.remember)
            resolve(resp)
        })
        .catch(err => {
            commit(AUTH_ERROR, err)
            reject(err)
        })
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token"); 
      localStorage.removeItem("user-token-refresh");
      localStorage.removeItem("user-json"); 
      delete axios.defaults.headers.common['Authorization']
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_REMEMBER]: (state, remember) => {
    if(remember==true){
      state.refreshToken = true;
    }
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.loginjson = resp.data;
    state.token = resp.data.accessToken;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.loginjson = "";
    state.refreshToken = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
