<template>
  <div class="home">
    <LoginForm v-if="!isAuthenticated" msg="Welcome to Your Vue.js App2"/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "actions/auth";

export default {
  name: 'Home',
  components: {
    LoginForm
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded","loginjson"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => `${state.user.profile.title} ${state.user.profile.name}`
    })
  }
}
</script>
