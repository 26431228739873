import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store";
import Login from '../views/Login.vue'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users',
    name: 'Users',
    props: true,
    component: () => import('../views/Users.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users/new/',
    name: 'UsersDetailNew',
    props: true,
    component: () => import('../views/UsersDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users/edit/:id',
    name: 'UsersDetail',
    component: () => import('../views/UsersDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/companies',
    name: 'Companies',
    props: true,
    component: () => import('../views/Companies.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/companies/edit/:id',
    name: 'CompaniesDetail',
    component: () => import('../views/CompaniesDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/companies/new/',
    name: 'CompaniesDetailNew',
    props: true,
    component: () => import('../views/CompaniesDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups',
    name: 'Group',
    props: true,
    component: () => import('../views/Group.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/groups/edit/:id',
    name: 'GroupDetail',
    component: () => import('../views/GroupDetailNew.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/groups/new/',
    name: 'GroupDetailNew',
    props: true,
    component: () => import('../views/GroupDetailNew.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/alarms',
    name: 'Alarms',
    props: true,
    component: () => import('../views/Alarms.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machines',
    name: 'Machines',
    props: true,
    component: () => import('../views/Machines.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machines/edit/:id',
    name: 'MachinesDetail',
    component: () => import('../views/MachinesDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/machines/new/',
    name: 'MachinesDetailNew',
    props: true,
    component: () => import('../views/MachinesDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machinest',
    name: 'MachinesType',
    props: true,
    component: () => import('../views/MachinesType.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machinest/edit/:id',
    name: 'MachinesTypeDetail',
    component: () => import('../views/MachinesTypeDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/machinest/new/',
    name: 'MachinesTypeDetailNew',
    props: true,
    component: () => import('../views/MachinesTypeDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machinesg',
    name: 'MachinesGroups',
    props: true,
    component: () => import('../views/MachinesGroups.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/machinest/edit/:id',
    name: 'MachinesGroupsDetail',
    component: () => import('../views/MachinesGroupsDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/machinest/new/',
    name: 'MachinesGroupsDetailNew',
    props: true,
    component: () => import('../views/MachinesGroupsDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/devices',
    name: 'Devices',
    props: true,
    component: () => import('../views/Devices.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/devices/edit/:id',
    name: 'DevicesDetail',
    component: () => import('../views/DevicesDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/devices/new/',
    name: 'DevicesDetailNew',
    props: true,
    component: () => import('../views/DevicesDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/serial',
    name: 'Serials',
    props: true,
    component: () => import('../views/Mint.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/serial/edit/:id',
    name: 'SerialsDetail',
    component: () => import('../views/MintDetail.vue'),
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/serial/new/',
    name: 'SerialsDetailNew',
    props: true,
    component: () => import('../views/MintDetail.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  methods: {
    checkJwt: function(){
      const decodedToken = VueJwtDecode.decode(localStorage.getItem('user-token'))
      return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
    }
  }
})
export default router
