<template>
  <div id="app" v-bind:class="{'dashboard':(this.$route.name=='Dashboard')? true : false}">
  <div class="theme_1">
    <div class="antialiased navigation block md:hidden">
      <div class="w-full text-gray-700">
        <div class="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          <div class="flex flex-row items-center justify-between p-4">
            <a href="#" class="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg focus:outline-none focus:shadow-outline"><img v-if="isAuthenticated" :src="loginJson.user.logo"></a>
            <button class="rounded-lg md:hidden focus:outline-none focus:shadow-outline" @click="open = !open">
              <svg fill="#fff" viewBox="0 0 20 20" class="w-6 h-6">
                <path v-show="!open" fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                <path v-show="open" fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </button>
          </div>
          <nav :class="{'flex': open, 'hidden': !open}" class="flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row">
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/dashboard" v-if="isAuthenticated && !authLoading">Dashboard</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/users" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null)">Users</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/groups" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_group' ))">Groups</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/companies" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null && loginJson.rules.some( item => item === 'list_companies' ))">Company</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/machines" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_machine' ))">Machines</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/machinesg" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_machine' ))">Machines Groups</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/machinest" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_machine' ))">Machines Type</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/alarms" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Alarms DB</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/devices" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Devices</router-link>
            <router-link tag="a" @click.native="open = !open" class="px-4 py-2 mt-2 text-white text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" to="/serial" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Serials</router-link>
          </nav>
        </div>
      </div>
    </div>
    <nav id="nav" class="w-full hidden md:block theme_1" v-if="isAuthenticated && !authLoading" @mouseleave="isMachine=false">
      <div class="flex flex-col md:flex-row md:justify-between md:items-center">
        <div class="flex justify-between items-center w-full px-2 py-3">
          <div class="flex items-center" id="logo" v-if="isAuthenticated && !authLoading"><img :src="loginJson.user.logo"></div>
          <div class="flex md:hidden">
            <button @click="isOpen = !isOpen" type="button" class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="toggle menu">
              <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
                <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
              </svg>
            </button>
          </div>
          <div class="md:flex items-center justify-between" :class="isOpen ? 'block' : 'hidden'" id="nav-link" v-if="isAuthenticated">
            <div class="grid grid-cols-8 divide-x divide-green-500" :class="'theme_'+loginJson.user.theme">
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/dashboard" @mouseover.native="isMachine=false" v-if="isAuthenticated && !authLoading">Dashboard</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/users" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null)">Users</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/groups" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_group' ))">Groups</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/companies" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null && loginJson.rules.some( item => item === 'list_companies' ))">Company</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/machines" @mouseover.native="isOver" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_machine' ))">Machines</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/alarms" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">AlarmsDB</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/devices" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Devices</router-link>
              <router-link tag="a" class="px-2 xl:text-lg lg:text-md" to="/serial" @mouseover.native="isMachine=false" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Serials</router-link>
            </div>
          </div>
          <div id="users" v-if="isAuthenticated && !authLoading" class="border-l border-white-100 pl-3 z-50">
            <div id="users-top">
              <img src="./assets/ico_user.png"> <span v-if="loginJson !== null && loginJson.user">{{loginJson.user.company_name}}</span>
            </div>
            <div id="user-bottom">
            </div>
            <div class="group inline-block">
              <button class="outline-none focus:outline-none px-3 py-1 flex items-center min-w-32 cursor-pointer">
                <span class="text-center flex-1">{{loginJson.user.display_name}}</span>
                <span>
                  <svg class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                  </svg>
                </span>
              </button>
              <ul class="z-30 bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
                <li class="rounded-sm px-3 text-gray-600 py-1 hover:bg-gray-100 cursor-pointer"><i class="fa fa-fw fa-download"></i> VPN Certificate</li>
                <li class="rounded-sm px-3 text-gray-600 py-1 hover:bg-gray-100 cursor-pointer"><i class="fa fa-fw fa-lock"></i> Reset VPN PIN</li>
                <li class="rounded-sm px-3 text-gray-600 py-1 hover:bg-gray-100 cursor-pointer"><i class="fa fa-fw fa-cog"></i> Settings</li>
                <li><hr></li>
                <li class="rounded-sm px-3 text-gray-600 py-1 hover:bg-gray-100 cursor-pointer" @click="logout"><i class="fa fa-sign-out fa-fw"></i> Logout</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute w-full subnav z-48" v-bind:class="{'mostra':isMachine}">
        <div class="mx-auto w-2/4 py-4 text-center">
          <div class="grid grid-cols-3">
            <router-link to="/machines" tag="a" v-if="isAuthenticated && !authLoading">Machines</router-link>
            <router-link to="/machinesg" tag="a" v-if="isAuthenticated && !authLoading">Machines Group</router-link>
            <router-link to="/machinest" tag="a" v-if="isAuthenticated && !authLoading">Machines Type</router-link>
          </div>
        </div>
      </div>
    </nav>
    <!--<div  class="flex justify-between items-center px-2">
      <div id="logo" v-if="isAuthenticated && !authLoading"><img src="./assets/dellatoffola.png"></div>
      <div id="nav-link" v-if="isAuthenticated">
        <router-link to="/dashboard" v-if="isAuthenticated && !authLoading">Dashboard</router-link>
        <router-link to="/users" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null)">Utenti</router-link>
        <router-link to="/groups" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_group' ))">Gruppi</router-link>
        <router-link to="/companies" v-if="isAuthenticated && (loginJson !== null && loginJson.rules != null && loginJson.rules.some( item => item === 'list_companies' ))">Aziende</router-link>
        <router-link to="/machines" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_machine' ))">Macchine</router-link>
        <router-link to="/alarms" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Allarmi</router-link>
        <router-link to="/mint" v-if="isAuthenticated && (loginJson.rules != null && loginJson.rules.some( item => item === 'create_alarms' ))">Mint</router-link>
      </div>
      <div id="users" v-if="isAuthenticated && !authLoading">
        <div id="users-top">
          <img src="./assets/ico_user.png"> <span v-if="loginJson !== null && loginJson.user">{{loginJson.user.display_name}}</span>
        </div>
        <div id="user-bottom">
        </div>
        <a v-if="isAuthenticated && !authLoading" @click="logout">Logout</a>
      </div>
    </div>
    <div class="subnav">
      <div v-if="$router.currentRoute.name == 'Machines'">
        <ul>
          <router-link to="" tag="li" v-if="isAuthenticated && !authLoading">Gruppi Macchine</router-link>
          <router-link to="" tag="li" v-if="isAuthenticated && !authLoading">Tipi Macchina</router-link>
          <router-link to="" tag="li" v-if="isAuthenticated && !authLoading">Macchine</router-link>
        </ul>
      </div>
    </div>-->
    <router-view/>
    </div>
  </div>
</template>
<style>
  li>ul                 { transform: translatex(100%) scale(0) }
  li:hover>ul           { transform: translatex(101%) scale(1) }
  li > button svg       { transform: rotate(-90deg) }
  li:hover > button svg { transform: rotate(-270deg) }

  .group:hover .group-hover\:scale-100 { transform: scale(1) }
  .group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
  .scale-0 { transform: scale(0) }
  .min-w-32 { min-width: 8rem }
  #app.dashboard { 
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url(./assets/bg_dashboard.jpg); background-repeat: no-repeat;background-size: cover;background-position: center;position: absolute;height:100%;width:100%;
  }
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT,AUTH_REFRESH } from "actions/auth";
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: "navigation",
  data: () => ({
    //userJson : loginJson
    isOpen: false,
    open: false,
    isDashboard: false,
    isMachine: false
  }),
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    },
    checkJwt: function(){
      const decodedToken = VueJwtDecode.decode(localStorage.getItem('user-token'))
      return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
    },
    isOver(){
      this.isMachine = true
    }
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded","loginJson","isRefresh","authStatus"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => ``
    })
  },
  watch:{
    isMachine(){
      if(this.$router.currentRoute.name.includes('Machines')){
        this.isMachine = true
      }
    }
  },
  updated: function updated () {
    console.log('entrato nel controllo del token')
    if(localStorage.getItem("user-token")!=null && localStorage.getItem("user-token")!=''){
      console.log('Token: '+this.checkJwt());
      if(!this.checkJwt()){
        if(this.isRefresh){
          console.log('Richiamo il refreshToken')
          this.$store.dispatch(AUTH_REFRESH);
        }else{
          this.isMachine = false
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
        }
      }
    }
  },
  beforeMount: function beforeMount () {
    if(localStorage.getItem("user-token")!=null && localStorage.getItem("user-token")!=''){
      console.log('Token: '+this.checkJwt());
      if(!this.checkJwt()){
        if(this.isRefresh){
          console.log('Richiamo il refreshToken')
          this.$store.dispatch(AUTH_REFRESH);
        }else{
          this.isMachine = false
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
        }
      }
    }
    if(this.$router.currentRoute.name.includes('Machines')){
      this.isMachine = true
    }
  }
};
</script>
