import Vue from 'vue'
import vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from 'vue-i18n'
import VeeValidate from "vee-validate";
//import "@blexar/framework";
import VModal from 'vue-js-modal'
import '@desislavsd/vue-select/dist/vue-select.css'
import VueSelect from '@desislavsd/vue-select'

require ('./assets/tailwind.css');
require ('./assets/tailwind_theme.scss');

//require ('./assets/main.scss');
Vue.config.productionTip = false
Vue.use(VeeValidate);
Vue.use(VModal)
Vue.use(VueSelect, { /* options */ } )

new Vue({
  router,
  store,
  vuex,
  axios,
  i18n,
  render: h => h(App),
  data: {
    user: false
  },
}).$mount('#app')
