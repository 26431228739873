<template>
<div>
  <div class="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
      <div class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
        <div class="p-4 py-6 text-white bg-gray-700 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
          <div class="my-3 text-4xl font-bold tracking-wider text-center">
            <img src="images/logo_login.png">
          </div>
          <p class="mt-6 font-normal text-center text-gray-300 md:mt-0">
            Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore magna aliqua.
          </p>
          <p class="flex flex-col items-center justify-center mt-10 text-center">
            <span>Don't have an account?</span>
            <a href="#" class="underline">Get Started!</a>
          </p>
          <p class="mt-6 text-sm text-center text-gray-300">
            Read our <a href="#" class="underline">terms</a> and <a href="#" class="underline">conditions</a>
          </p>
        </div>
        <div class="p-5 bg-white md:flex-1">
          <h3 class="my-4 text-2xl font-semibold text-gray-700">Account Login</h3>
          <div v-if="!status" class="bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-3/4 xl:w-2/4">
            <svg viewBox="0 0 24 24" class="text-red-600 w-5 h-5 sm:w-5 sm:h-5 mr-3">
              <path fill="currentColor" d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"></path>
            </svg>
            <span class="text-red-800"> Your email address or password is invalid. </span>
          </div>
          <form @submit.prevent="login" class="flex flex-col space-y-5" autocomplete="off">
            <div class="flex flex-col space-y-1">
              <label for="email" class="text-sm font-semibold text-gray-500">Username or Email address</label>
              <input
                type="text"
                name="user_name"
                id="user_name"
                required
                v-model="user_name"
                v-validate="'required'"
                autofocus
                autocomplete="off"
                :class="{'px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200':true,'border-red-500': errors.has('user_name') }"
              />
              <span v-show="errors.has('user_name')" class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{{ errors.first('user_name') }}</span>
            </div>
            <div class="flex flex-col space-y-1">
              <div class="flex items-center justify-between">
                <label for="password" class="text-sm font-semibold text-gray-500">Password</label>
                <a href="#" class="text-sm text-blue-600 hover:underline focus:text-blue-800">Forgot Password?</a>
              </div>
              <input
                required
                v-model="password"
                name="password"
                type="password"
                v-validate="'required'"
                id="password"
                autocomplete="off"
                :class="{'px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200':true,'border-red-500': errors.has('password') }"
              />
              <span v-show="errors.has('password')" class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">{{ errors.first('password') }}</span>
            </div>
            <div class="flex items-center space-x-2">
              <input
                type="checkbox"
                v-model="remember"
                id="remember"
                value="1"
                class="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
              />
              <label for="remember" class="text-sm font-semibold text-gray-500">Remember me</label>
            </div>
            <div>
              <button type="submit" @click="login" class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4">
                Log in
              </button>
            </div>
            <div class="flex flex-col space-y-5">
              <span class="flex items-center justify-center space-x-2">
                <span class="h-px bg-gray-400 w-14"></span>
                <span class="font-normal text-gray-500">or login with</span>
                <span class="h-px bg-gray-400 w-14"></span>
              </span>
              <div class="flex flex-col space-y-4">
                <a href="#" class="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-red-500 rounded-md group hover:bg-red-500 focus:outline-none">
                  <span>
                    <i class="fab fa-google text-red-500 group-hover:text-white"></i>
                  </span>
                  <span class="text-sm font-medium text-red-500 group-hover:text-white">Google</span>
                </a>
                <a href="#" class="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-blue-500 rounded-md group hover:bg-blue-500 focus:outline-none">
                  <span>
                    <i class="fab fa-facebook-f text-blue-800 group-hover:text-white"></i>
                  </span>
                  <span class="text-sm font-medium text-blue-500 group-hover:text-white">Facebook</span>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    user_name: {
      required: 'Username is empty!'
    },
    password: {
      equired: 'Password is empty!'
    }
  }
};

Validator.localize('en', dict);

export default {
  name: "LoginForm",
  data() {
    return {
      user_name: "",//demo1
      password: "", //demo123
      remember: "",
      status: true
    };
  },
  components:{
    Validator
  },
  methods: {
    login: function(e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const { user_name, password, remember } = this;
          this.$store.dispatch(AUTH_REQUEST, { user_name, password, remember }).then(() => {
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            this.status = false;
          });
          return;
        }
      });
    }
  }
};
</script>
